import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/cut-off-machines/professional-cut-off-machines/',
    imageId: 'professionalCutOffMachinesImage',
    name: 'Professional Cut-Off Machines',
    alt: 'STIHL Professional Cut-Off Machines',
  },
  {
    link: '/stihl/cut-off-machines/battery-cut-off-machines/',
    imageId: 'batteryCutOffMachinesImage',
    name: 'Battery Cut-Off Machines',
    alt: 'STIHL Battery Cut-Off Machines',
  },
]

const CutOffMachinesPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Cut-Off Machines | Hutson Inc</title>
        <meta
          name='description'
          content='Dependable, powerful, and maneuverable, STIHL Cut-Off Machines were built for the work site. Find the right cut-off machine for any job at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Cut-Off Machines',
                'item': 'https://www.hutsoninc.com/stihl/cut-off-machines/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Cut-Off Machines' />

      <Content>
        <Floater items={floaterItems} />

        <Promos data={promos} type='STIHL Cut-Off Machine' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query cutOffMachinesStihlQuery($category: String = "cut-off-machines") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/cut-off-machines/cut-off-machines-bg.jpg" }) {
      ...FullWidthImage
    }
    professionalCutOffMachinesImage: file(
      relativePath: { eq: "stihl/cut-off-machines/professional-cut-off-machines.jpg" }
    ) {
      ...FloatingGridImage
    }
    batteryCutOffMachinesImage: file(
      relativePath: { eq: "stihl/cut-off-machines/battery-cut-off-machine.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default CutOffMachinesPage
